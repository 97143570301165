import axios from 'axios'
const {planet = {}} = window || {}

// const getBasePlanet = () => {
//   return axios.create({
//     baseURL: 'https://api.digital-planet.ksauto.net/dev-promptstart-services/v1',
//   })
// }

const getBasePlanet = () => {
  const baseUrl = planet.PLANET_EP_URL
  const path = planet.PS_SERVICES_PATH
  console.log(`baseUrl: ${baseUrl}, path ${path}`)

  return axios.create({
    baseURL: `${baseUrl}${path}`,
  })
}

export default getBasePlanet
