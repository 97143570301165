import getBasePlanet from './basePlanet'

const {planet = {}} = window || {}
const {post} = getBasePlanet(true)

// export const requestToken = async () => {
//   try {
//     const response = await post('/ps-dld/token', {}, {
//       headers: {
//         Authorization: 'Basic MnA1ZmIwZXJicmtjZmhkYjBjNTR1NWhtZXI6MWFxaWZrcnJsZm0yZGhxNmEzazBrMmlpbjRzYmFxbmU4dWJxbDkwNTdsdDRjMzRsOXBvcQ==',
//       },
//     })

//     if (response.status === 200) {
//       return response.data.access_token
//     } else {
//       throw new Error('Failed to retrieve token')
//     }
//   } catch (error) {
//     console.error('Error in requestToken:', error)
//     throw error
//   }
// }

export const requestToken = async () => {
  try {
    const clientId = planet.PS_SERVICES_CLIENT_ID
    const clientSecret = planet.PS_SERVICES_CLIENT_SECRET
    const authString = `${clientId}:${clientSecret}`
    const authBase64 = btoa(authString)
    const authHeader = `Basic ${authBase64}`
    const response = await post(
      '/ps-dld/token',
      {},
      {
        headers: {
          Authorization: authHeader,
        },
      },
    )

    if (response.status === 200) {
      return response.data.access_token
    } else {
      throw new Error('Failed to retrieve token')
    }
  } catch (error) {
    console.error('Error in requestToken:', error)
    throw error
  }
}

export const saveData = (phoneNumber, token) => {
  post(
    '/ps-dld/requests',
    {
      mobile_no: phoneNumber,
      purchase_now: 'Y',
      channel: 'JET',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).catch(error => {
    console.error('Error in saveData:', error)
  })
}

export default {
  requestToken,
  saveData,
}
